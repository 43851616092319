
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import RadioButton from "primevue/radiobutton";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import debounce from "lodash/debounce";
import { formatDate, numberOnly, storeDatatoLocalStorage } from "@/app/infrastructures/misc/Utils";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { gtmCargoData } from "../../modules/gtm";
import { AirportData } from "@/domain/entities/Cargo";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";

@Options({
  emits: ["closePopup"],
  components: {
    CustomDropdown,
    RadioButton
  }
})
export default class ModalPlaneAuto extends Vue {
  // airport
  airportMappingData(data: AirportData[]) {
    return data.map(airport => ({
      ...airport,
      name: `${airport.airportCode} - ${airport.airportName}`,
      value: airport.airportCode
    }));
  }

  mounted() {
    this.isAllowEditCutoff();
  }

  onChangeTotalCargoGrossWeight(value: number) {
    CargoController.setOnChangeTotalActualGrossWeight(value);
  }

  onChangeThresholdTime(value: number) {
    CargoController.setOnChangeThresholdTime(value);
  }

  onChangeTotalCargoVolumeWeight(value: number) {
    CargoController.setOnChangeTotalCargoVolumeWeight(value);
  }

  public setPeriodeStart(date: Date) {
    const estDepartureDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const estArrivalDate = this.$moment(date)
      .add(6, "days")
      .toISOString();
    this.form.estDepartureDate = estDepartureDate;
    this.form.estArrivalDate = estArrivalDate;

    const cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    cargoInputForm.estDepartureDate = estDepartureDate;
    cargoInputForm.estArrivalDate = estArrivalDate;
    storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
  }

  get showSelectAirport() {
    const isShow = CargoController.showSelectAirport;
    if (isShow && !this.isRtc && this.form.cargoType) {
      const gtmPopupView = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true
      }).popupView;

      dataLayer(gtmPopupView.eventName, gtmPopupView.property, []);
    }
    return isShow;
  }
  set showSelectAirport(bool: boolean) {
    CargoController.setShowSelectAirport(bool);
  }
  set showSelectAirportToLocalStorage(bool: boolean) {
    CargoController.setShowSelectAirportToLocalStorage(bool);
  }
  get airportOrigin() {
    return CargoController.form.airportOrigin;
  }

  get optionAirportOrigin() {
    return this.airportMappingData(CargoController.airportDataOrigin.data);
  }

  numberOnly(value: string) {
    return numberOnly(value);
  }

  // airport list
  get isPlaneAuto() {
    return (
      this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto"
    );
  }
  fetchAirport = debounce((search: string) => {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (!search.length || search.length >= 3) {
      CargoController.getAirportList({
        airportCode: "",
        cityCode: "",
        page: 1,
        limit: 10,
        isLocation: true,
        search: search
      });
    }
  }, 250);
  get isLoadingAirport() {
    return CargoController.isLoadingAirport;
  }
  get airportData() {
    return CargoController.airportData.data
      .map(item => ({
        ...item,
        airportName: `${item.airportCode} - ${item.airportName}`
      }))
      .filter(
        item =>
          !this.airportsPopupModel.find(
            (el: any) => item.airportCode === el.airportCode
          )
      );
  }

  selectAirportOrigin(item: any) {
    if (this.airportOrigin.name == item.name) {
      CargoController.form.airportOrigin = {
        airportCode: "",
        airportName: "",
        cityCode: "",
        cityName: "",
        name: "",
        value: ""
      };
      return;
    }
    CargoController.setDefaultAirportOrigin(item);
    const mapName = {
      ...item,
      airportName: `${item.airportCode} - ${item.airportName}`
    };
    if (this.airportsPopupModel.length > 1) {
      this.airportsPopupModel[0] = mapName;
      CargoController.form.airports[0] = mapName;
    } else {
      this.airportsPopupModel.unshift(mapName);
      CargoController.form.airports.unshift(mapName);
    }
  }

  get airportDestination() {
    return CargoController.form.airportDestination;
  }

  get optionAirportDestination() {
    return this.airportMappingData(CargoController.airportDataDestination.data);
  }

  selectAirportDestination(item: any) {
    if (this.airportDestination.name == item.name) {
      CargoController.form.airportDestination = {
        airportCode: "",
        airportName: "",
        cityCode: "",
        cityName: "",
        name: "",
        value: ""
      };
    } else {
      CargoController.form.airportDestination = item;
      const mapName = {
        ...item,
        airportName: `${item.airportCode} - ${item.airportName}`
      };
      this.airportsPopupModel[this.airportsPopupModel.length - 1] = mapName;
      CargoController.form.airports[this.airportsPopupModel.length - 1] = mapName;
    }
  }

  get cargoData() {
    return CargoController.cargoType;
  }

  get form() {
    return CargoController.form;
  }

  get isRtc() {
    return this.form.isRtc;
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  // transit total
  get airportsPopupModel() {
    return CargoController.airportsPopupModel;
  }

  setAirportPopUpModel(val: any) {
    CargoController.setAirportsPopupModel(val);
  }
  setDefaultDataToAirportPopup() {
    this.setAirportPopUpModel(JSON.parse(JSON.stringify(this.form.airports)));
  }

  get transitsList() {
    return [
      { name: "Langsung", value: "0" },
      { name: "1 Transit", value: "1" },
      { name: "2 Transit", value: "2" },
      { name: "3 Transit", value: "3" }
    ];
  }
  onChangeTransitTotal(total: number) {
    this.form.transit = String(total);
    const firstData = this.airportsPopupModel;
    // each change total will reset to origin & destination only in airports
    this.setAirportPopUpModel([firstData[0], firstData[firstData.length - 1]]);

    // assign total transits to airpots
    const transits: Array<string> = Array(total).fill("");
    this.airportsPopupModel.splice(1, 0, ...transits);
  }
  cancelPopupAirport() {
    storeDatatoLocalStorage("is-change-threshold-time", false);
    this.setDefaultDataToAirportPopup();
    this.showSelectAirport = false;
    this.form.isChangeAirport = false;
    this.showSelectAirportToLocalStorage = false;
    if (this.flowBooking === "rtc-edit") {
      CargoController.form.cargoType = this.detail.cargoType;
    }
    this.$emit("closePopup");
    if (this.flowBooking  !== 'rebooking' && !CargoController.isChangeVolumeActual && CargoController.progressValue !== 1) {
      this.isAllowEditCutoff();
      if (!this.isEditCutOff) {
      CargoController.setCargoType({type: "", status: ""});
    }
      CargoController.calculationVolumeWeight(this.form.actualTotalSTTPieces)
    }
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  isEditCutOff = false;

  isAllowEditCutoff(){
    const today = { hours: formatDate(new Date())?.split(",")[1]?.split(":")[0]?.trim(), minutes: formatDate(new Date())?.split(",")[1]?.split(":")[1]?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, '')?.trim()};
    const isEditRtcCutoff = ReadyToCargoController.flowBooking === 'rtc-edit' && this.isPlaneAuto && this.cutOffTime.hours !== undefined && this.cutOffTime.minutes !== undefined
    const isAllowed = ((+this.cutOffTime.hours < +today.hours) || (+this.cutOffTime.hours === +today.hours && +this.cutOffTime.minutes < +today.minutes)) && CargoController.progressValue !== - 1;
    this.isEditCutOff = isEditRtcCutoff && isAllowed;
    return isEditRtcCutoff && isAllowed;
  } 
  

  get cutOffTime() {
    return {hours: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(":")[0], minutes: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(":")[1] };
  }

  get isDisabledAirport() {
    return (
      !this.airportOrigin.name ||
      !this.airportDestination.name ||
      !this.form.estDepartureDate ||
      !parseFloat(
        `${this.form.actualTotalCargoGrossWeight}`.replace(",", ".")
      ) ||
      !parseFloat(
        `${this.form.actualTotalCargoVolumeWeight}`.replace(",", ".")
      ) ||
      !this.form.transit ||
      this.airportsPopupModel.indexOf("") >= 0 ||
      this.form.thresholdTime < 1
    );
  }
  onChooseButtonAirport() {
    CargoController.resetFlightData(); //reset flightData if choose button lanjut / change schedule on modal plane
    if (this.airportOrigin.name && this.airportDestination.name) {
      CargoController.setPlaneAutoFlow(true);
      this.form.airports = JSON.parse(JSON.stringify(this.airportsPopupModel));
      this.form.transit = JSON.parse(JSON.stringify(this.form.transit));
      this.showSelectAirport = false;
      this.form.flightsData = [];
      CargoController.setShowPlaneSchedule(false);
      this.$nextTick(() => {
        CargoController.setShowPlaneSchedule(true);
      });
    }
    if (this.isRtc) {
      CargoController.setProgressValue(1);
    }
    if(this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto") {
      CargoController.setProgressValue(1);
    }
    this.showSelectAirportToLocalStorage = false;
  }

  get detail() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }
}


import { formatDateWithoutTime } from "@/app/infrastructures/misc/Utils";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  icon = prop<string>({
    default: "",
    type: String
  });
  type = prop<string>({
    default: "",
    type: String
  });
  isManual = prop<boolean>({
    default: true,
    type: Boolean
  });
  origin = prop<string>({
    default: "",
    type: String
  });
  destination = prop<string>({
    default: "",
    type: String
  });
  isRecommended = prop<boolean>({
    default: false,
    type: Boolean
  });
  isActive = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  emits: "choose"
})
export default class CargoTypeCard extends Vue.with(Props) {
  onClick() {
    this.$emit("choose");
  }

  get defaultRangeToday() {
    const sevenDayAfterToday = new Date();
    sevenDayAfterToday.setDate(sevenDayAfterToday.getDate() + 6);
    return `${formatDateWithoutTime(new Date().toString())} -
     ${formatDateWithoutTime(new Date(sevenDayAfterToday).toString())}`;
  }
}
